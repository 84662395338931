<template>
  <div>
    <title>SIM INVENTORY ~ ADD COA DATA LISTS</title>
    <section class="content-header">
      <h1>
        Add COA Data Lists
        <br />
        <h4>Please enter COA data lists</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Accounting Lists COA</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Add COA Data List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">COA Code</label>
                  <input
                    type="text"
                    v-model="isidata.coacode"
                    autocomplete="off"
                    class="form-control"
                    placeholder="COA Code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">COA Name</label>
                  <input
                    type="text"
                    v-model="isidata.coaname"
                    autocomplete="off"
                    class="form-control"
                    placeholder="COA Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Kurs</label>
                  <input
                    type="text"
                    v-model="isidata.valkurs"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Kurs"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Type</label>
                  <select2
                    :data="barangtipe"
                    :value="valuetype"
                    @update="updatetype($event)"
                  ></select2>

                  <!--<select
                    class="form-control"
                    v-model="isidata.tipebarang"
                    id="tipebarang"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="tipebrg in barangtipe"
                      :key="tipebrg.tipe"
                      :value="tipebrg.tipe"
                    >
                      {{ tipebrg.tipe }}
                    </option>
                  </select> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                  <!--<select
                    class="form-control"
                    v-model="isidata.kodesupplier"
                    id="kdsup"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="listsupp in suplist"
                      :key="listsupp.kode_supplier"
                      :value="listsupp.kode_supplier"
                    >
                      {{ listsupp.nama_supplier }}
                    </option>
                  </select> -->
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Add COA Data List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notescoa"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes COA"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">POS</label>
                  <select class="form-control" v-model="isidata.pos" id="pos">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datapos in pos"
                      :key="datapos.id"
                      :value="datapos.value"
                    >
                      {{ datapos.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SN</label>
                  <select class="form-control" v-model="isidata.sn" id="sn">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datasn in sn"
                      :key="datasn.id"
                      :value="datasn.value"
                    >
                      {{ datasn.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Initial Debit Balance</label>
                  <input
                    type="text"
                    v-model="isidata.saldoawald"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Initial Debit Balance"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Initial Credit Balance</label>
                  <input
                    type="text"
                    v-model="isidata.saldoawalk"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Initial Credit Balance"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/coa">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addcoa",
  data() {
    return {
      pos: [
        { value: "BALANCE", id: 1 },
        { value: "PROFIT AND LOSS", id: 2 },
      ],
      sn: [
        { value: "DEBIT", id: 1 },
        { value: "CREDIT", id: 2 },
      ],
      loading: false,
      barangtipe: [],
      suplist: [],
      valuesup: "",
      valuetype: "",
      isidata: {
        coacode: "",
        coaname: "",
        notescoa: "",
        valkurs: "",
        pos: "",
        sn: "",
        saldoawald: "",
        tipebarang: "",
        kodesupplier: "",
        saldoawalk: "",
      },
    };
  },
  created() {
    this.getbarangtipe();
    this.getSupplierList();
  },
  methods: {
    updatesup(value){
      this.valuesup = value;
    },
    updatetype(value){
      this.valuetype = value;
    },
    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    getbarangtipe() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.barangtipe = resp.data.data;
          this.barangtipe.forEach((item) => {
            item.value = item.tipe;
            item.label = item.tipe;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async saveData() {
      this.loading = true;
      var codecoa = this.isidata.coacode;
      var namecoa = this.isidata.coaname;
      var coanotes = this.isidata.notescoa;
      var poscoa = this.isidata.pos;
      var sncoa = this.isidata.sn;
      var debsaldoawal = this.isidata.saldoawald;
      var kresaldoawal = this.isidata.saldoawalk;
      var sup = this.valuesup;
      var tip = this.valuetype;
      var kur = this.isidata.valkurs;

      if (namecoa == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "COA Name can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (sncoa == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SN COA can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (poscoa == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "POS COA can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          // class_id: idclass,
          // class_name: namaclass,
          // subclass_id: idsubclass,
          // subclass_name: subnameclass,
          coa_code: codecoa,
          coa_name: namecoa,
          notes: coanotes,
          pos: poscoa,
          sn: sncoa,
          saldo_awal_d: debsaldoawal,
          saldo_awal_k: kresaldoawal,
          kode_user: kodeuser,
          kode_supplier: sup,
          tipe_barang: tip,
          kurs: kur,
        };
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/coa/savecoa";
        const urlAPIsavebrg = this.$apiurl + "coa/savecoa";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        axios
          .post(urlAPIsavebrg, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false,
              });
              // this.isidata.coacode = "";
              // this.isidata.coaname = "";
              // this.isidata.valkurs = "";
              // this.valuetype = "";
              // this.valuesup = "";
              this.isidata.notescoa = "";
              this.isidata.pos = "";
              this.isidata.sn = "";
              this.isidata.saldoawald = "";
              this.isidata.saldoawalk = "";
              this.loading = false;
              // this.$router.push({ name: "coa" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false,
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>
